import React from 'react';
import { useKeenSlider } from 'keen-slider/react';
import 'keen-slider/keen-slider.min.css';
// import "./styles.css";
import { Link } from 'gatsby';
import LandingImageThree from '../images/LandingImageThree';
import LandingImageOne from '../images/LandingImageTwo';
import RMDCaseOne from '../images/RMDCaseOne';
import MicheroCaseTwo from '../images/MicheroCaseTwo';

export default props => {
  const [sliderRef] = useKeenSlider({
    slidesPerView: 2,
    breakpoints: {
      '(max-width: 1000px)': {
        slidesPerView: 1,
      },
    },
    mode: 'free-snap',
    spacing: 15,
    centered: true,
    loop: true,
  });

  return (
    <div ref={sliderRef} className="keen-slider">
      <div className="keen-slider__slide number-slide1">
        <Link to="/casestudies/mediservices" className="">
          <div className="card h-100 border-0 rounded-0 text-white">
            <LandingImageThree />
            <div className="card-img-overlay ov">
              <div className="row p-4  h-100">
                <div className="card align-self-start w-75 align-self-end p-md-5 p-3 rounded shadow-sm  border-0 bg-white">
                  <small className="card-title text-primary text-uppercase font-weight-bold">Case study</small>
                  {/* <h3 className="card-title text-black">Mediservices Ltd</h3> */}
                  <p className="card-text case-text text-neutralDark">
                    MediServices is the leading supplier of Neurophysiology services.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </Link>
      </div>
      <div className="keen-slider__slide number-slide2">
        <Link to="/casestudies/revivemydevice" className="">
          <div className="card h-100 border-0 rounded-0 text-white">
            <RMDCaseOne />
            <div className="card-img-overlay ov">
              <div className="row p-4 h-100">
                <div className="card w-75 align-self-end p-md-5 p-3 rounded shadow-sm border-0 bg-white">
                  <small className="card-title text-primary text-uppercase font-weight-bold">Case study</small>
                  {/* <p className="card-title border-primary border-top-0 border-bottom border-right-0 border-left-0 text-muted">Marcote Ltd</p> */}
                  <p className="case-text text-neutralDark">
                    Revive My Device will restore your device back to its former glory.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </Link>
      </div>
      <div className="keen-slider__slide number-slide3">
        <Link to="/casestudies/marcote" className="">
          <div className="card h-100 border-0 rounded-0 text-white">
            <LandingImageOne />
            <div className="card-img-overlay ov">
              <div className="row p-4 h-100">
                <div className="card w-75 align-self-end p-md-5 p-3 rounded shadow-sm border-0 bg-white">
                  <small className="card-title text-primary text-uppercase font-weight-bold">Casestudy</small>
                  {/* <p className="card-title border-primary border-top-0 border-bottom border-right-0 border-left-0 text-muted">Marcote Ltd</p> */}
                  <p className="case-text text-neutralDark">Marcote is a specialist coatings application company.</p>
                </div>
              </div>
            </div>
          </div>
        </Link>
      </div>
      <div className="keen-slider__slide number-slide3">
        <Link to="/casestudies/micherowholefoods" className="">
          <div className="card h-100 border-0 rounded-0 text-white">
            <MicheroCaseTwo />
            <div className="card-img-overlay ov">
              <div className="row p-4 h-100">
                <div className="card w-75 align-self-end p-md-5 p-3 rounded shadow-sm border-0 bg-white">
                  <small className="card-title text-primary text-uppercase font-weight-bold">Casestudy</small>
                  {/* <p className="card-title border-primary border-top-0 border-bottom border-right-0 border-left-0 text-muted">Marcote Ltd</p> */}
                  <p className="case-text text-neutralDark">Michero Yedu Wholefoods specialises in superfoods</p>
                </div>
              </div>
            </div>
          </div>
        </Link>
      </div>
    </div>
  );
};
