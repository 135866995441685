import React from 'react';
import VideoPlayer from 'react-background-video-player';
import TypedText from '../typedText';
import BgImag from '../../../static/images/tea.jpeg';
// import "./styles.css";

const BgVideo = () => (
  <div className="">
    <div className="card border-0 text-white">
      <img src={BgImag} className=" card-img" alt="..." />
      <div className="card-img-overlay">
        <div className="col-md-12 mx-auto">
          <TypedText />
        </div>
      </div>
    </div>
  </div>
);

export default BgVideo;
